import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {CategoryItemDto} from '../../shared/model/item/dto/category-item-dto';
import {CategoryType} from '../../shared/model/item/type/category-type';
import {DateCategoryType} from '../../shared/model/item/type/date-category-type';
import {MeasureItemDto} from '../../shared/model/item/dto/measure-item-dto';
import {MeasureType} from '../../shared/model/item/type/measure-type';
import {ItemDto} from '../../shared/model/item/dto/item-dto';
import {OrderedItemDto} from '../../shared/model/item/dto/ordered-item-dto';
import {ItemGroupDto} from '../../shared/model/item/dto/item-group-dto';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {FilterDto} from '../../shared/model/filter/dto/filter-dto';
import {FilterTreeDto} from '../../shared/model/filter/dto/filter-tree-dto';
import {AbstractFilterElementDto} from '../../shared/model/filter/dto/abstract-filter-element-dto';
import {FilterDtoBuilder} from '../../shared/model/filter/builder/filter-dto-builder';
import {FilterTreeOperatorDto} from '../../shared/model/filter/dto/filter-tree-operator-dto';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {ComboboxOption} from '../../shared/combobox-option';
import {ComboboxOptions} from '../../shared/combobox-options';
import {FilterGroup} from '../../shared/model/filter/filter-group';
import {FilterItem} from '../../shared/model/filter/filter-item';
import {ItemsMap} from '../../shared/items-map';
import {FilterType} from '../../shared/model/filter/type/filter-type';
import {FilterHelper} from '../../shared/model/helper/filter-helper';
import {CriterionValidator} from '../../shared/model/visitor/criterion-validator';
import {Model} from '../../shared/model/model';
import {EmptyValueLoader, ValueLoader} from '../../shared/model/provider/value-loader';
import {CurrencyValue} from '../../shared/model/item/value/currency-value';
import {Options} from '../../shared/model/options';


@Component({
	moduleId: module.id,
	selector: 'sis-common-filter',
	templateUrl: 'common-filter.component.html',
	styleUrls: ['common-filter.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CommonFilterComponent implements OnChanges, OnInit, OnDestroy {

	// Expose enums to be use in template.
	public FilterType: any = FilterType;
	public CategoryType: any = CategoryType;
	public DateCategoryType: any = DateCategoryType;
	public MeasureType: any = MeasureType;

	public selectedRadio: string;

	public itemsComboboxOptions: ComboboxOptions<ComboboxOption<ItemDto, string>>;
	public filterGroups: Array<FilterGroup>;
	public currencyComboboxOptions: ComboboxOptions<ComboboxOption<number, string>>;
	public measureComboboxOptions: ComboboxOptions<ComboboxOption<number, Map<number, string>>>;
	public categoryComboboxOptions: ComboboxOptions<ComboboxOption<number, Map<number, string>>>;
	public booleanMeasuresComboboxOptions: ComboboxOptions<ComboboxOption<number, string>>;
	public isError: boolean;

	@Input('valueLoader')
	public valueLoader: ValueLoader;

	@Input('model')
	private model: Model;

	@Input('filterTreeDTO')
	private filterTreeDto: FilterTreeDto;

	@Input('options')
	public options: Options;

	@Output('filterTreeDTOChange')
	private filterTreeDtoChange: EventEmitter<FilterTreeDto>;

	@Output('validityChange')
	private isValid: EventEmitter<boolean>;

	private itemsComboboxSubscription: Subscription;
	private validitySubscription: Subscription;
	private filterTreeDtoEmitted: FilterTreeDto;
	private currencyComboboxSubscription: Subscription;

	private lastScroll: any = 0;
	private isHeaderHidden = false;

	constructor(public currentLanguageProvider: CurrentLanguageProvider,
				public  messageService: MessageService) {
		PreconditionCheck.notNullOrUndefined(currentLanguageProvider);
		PreconditionCheck.notNullOrUndefined(messageService);
		if (!this.options) {
			this.options = new Options(false, false);
		}
		this.model = null;
		this.filterTreeDto = null;
		this.filterTreeDtoChange = new EventEmitter<FilterTreeDto>();
		this.isValid = new EventEmitter<boolean>();
		this.valueLoader = new EmptyValueLoader();
	}

	private _items: ItemsMap;

	get items(): ItemsMap {
		return this._items;
	}

	private _defaultCurrencyID = 2;

	public get defaultCurrencyID(): number {
		return this._defaultCurrencyID;
	}

	public static initSimpleComboboxOptions(items: Array<any>): ComboboxOptions<ComboboxOption<number, any>> {
		PreconditionCheck.notNullOrUndefined(items);

		const options: ComboboxOptions<ComboboxOption<number, any>> = [];

		items.forEach((item: any): void => {
			options.push(new ComboboxOption(item['id'], item['name']));
		});

		return options;
	}

	private static extractItems(source: ItemsMap, target: ItemsMap) {
		source.forEach((item: ItemDto): void => {
			target.set(item.id, item);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		const modelChange: SimpleChange = changes['model'];
		const filterTreeDtoChange: SimpleChange = changes['filterTreeDto'];

		if (this.model === null || this.model === undefined) {
			throw new Error('Value of input property \'model\' is mandatory.');
		}

		if (modelChange && !filterTreeDtoChange) {
			this.filterTreeDto = null;
		}

		if (modelChange && !modelChange.isFirstChange()) {
			this.resetState();
		} else if (filterTreeDtoChange && !filterTreeDtoChange.isFirstChange() && (this.filterTreeDtoEmitted === null || this.filterTreeDtoEmitted !== this.filterTreeDto)) {
			this.resetState();
		}
	}

	ngOnInit(): void {
		this.resetState();
	}

	ngOnDestroy(): void {
		this.clearState();
	}

	public canAddGroup(filterGroupIndex: number = null): boolean {
		PreconditionCheck.notUndefined(filterGroupIndex);

		if (filterGroupIndex === null) {
			if (this.filterGroups.length === 0) {
				return true;
			}

			const group: FilterGroup = this.filterGroups[this.filterGroups.length - 1];
			const item: FilterItem = group.filterItems[group.filterItems.length - 1];

			return item.criterion !== null;
		} else {
			return filterGroupIndex >= this.filterGroups.length - 1;
		}
	}

	public canAddItem(filterGroupIndex: number, filterItemIndex: number): boolean {
		PreconditionCheck.notNullOrUndefined(filterGroupIndex);
		PreconditionCheck.notNullOrUndefined(filterItemIndex);

		const group: FilterGroup = this.filterGroups[filterGroupIndex];
		const item: FilterItem = group.filterItems[filterItemIndex];

		return (filterItemIndex >= group.filterItems.length - 1) && item.criterion !== null;
	}

	public onAddGroup(): void {
		const filterGroup: FilterGroup = new FilterGroup();
		const filterItem: FilterItem = new FilterItem(this, new CriterionValidator());
		filterGroup.filterItems.push(filterItem);
		this.filterGroups.push(filterGroup);

		this.validate();
	}

	public onAddItem(filterGroupIndex: number): void {
		PreconditionCheck.notNullOrUndefined(filterGroupIndex);

		const filterGroup: FilterGroup = this.filterGroups[filterGroupIndex];
		const filterItem: FilterItem = new FilterItem(this, new CriterionValidator());
		filterGroup.filterItems.push(filterItem);

		this.validate();
	}

	public onRemoveItem(filterGroupIndex: number, filterItemIndex: number): void {
		PreconditionCheck.notNullOrUndefined(filterGroupIndex);
		PreconditionCheck.notNullOrUndefined(filterItemIndex);

		const filterGroup: FilterGroup = this.filterGroups[filterGroupIndex];
		const filterItems: Array<FilterItem> = filterGroup.filterItems;
		const deletedFilterItems: Array<FilterItem> = filterItems.splice(filterItemIndex, 1);

		if (deletedFilterItems[0]) {
			deletedFilterItems[0].clearState();
		}

		if (filterItems.length === 0) {
			this.filterGroups.splice(filterGroupIndex, 1);
		}

		// Add one group if none present, for user's convenience.
		if (this.filterGroups.length === 0) {
			this.filterGroups.push(this.createEmptyFilterGroup());
		}

		this.validate();
	}

	public validate(init: boolean = false): void {
		PreconditionCheck.notUndefined(init);
		this.resetValiditySubscription(init);
		this.validateItems();
	}

	private clearState() {
		this.clearItemsComboboxSubscription();
		this.clearCurrencyComboboxSubscription();
		this.clearValiditySubscription();

		this._items = new Map();
		this.itemsComboboxOptions = [];
		this.filterGroups = [];
		this.filterTreeDtoEmitted = null;
		this.measureComboboxOptions = [];
		this.categoryComboboxOptions = [];
		this.currencyComboboxOptions = [];
		this.isError = false;
	}

	private resetState(): void {
		this.clearState();

		if (this.model) {
			const items = this.getItems();
			const groups = this.getItemGroups();

			this._items = items;
			this.resetItemsComboboxSubscription(items, groups);
			this.filterGroups = this.fromFilterTreeDto();
			this.validate(true);
		}
	}

	private getItems(): ItemsMap {
		PreconditionCheck.notNullOrUndefined(this.model);

		const availableItems: ItemsMap = new Map();
		const items = this.model.items;

		CommonFilterComponent.extractItems(items.categoryItems, availableItems);
		CommonFilterComponent.extractItems(items.measureItems, availableItems);

		return availableItems;
	}

	private getItemGroups(): Array<ItemGroupDto> {
		PreconditionCheck.notNullOrUndefined(this.model);

		const groups = this.model.groups;

		groups.forEach((group: ItemGroupDto) => {
			group.items.sort((item1: OrderedItemDto, item2: OrderedItemDto): number => {
				return item1.sortId - item2.sortId;
			});
		});

		return groups;
	}

	private clearItemsComboboxSubscription(): void {
		if (this.itemsComboboxSubscription) {
			this.itemsComboboxSubscription.unsubscribe();
		}

		this.itemsComboboxSubscription = null;
	}

	private resetItemsComboboxSubscription(items: ItemsMap, groups: Array<ItemGroupDto>): void {
		PreconditionCheck.notNullOrUndefined(items);
		PreconditionCheck.notNullOrUndefined(groups);

		this.clearItemsComboboxSubscription();
		this.clearCurrencyComboboxSubscription();

		this.itemsComboboxSubscription = Observable
			.zip(
				this.currentLanguageProvider.getCurrentLanguage(),
				this.messageService.getMessage('reporting.controls.filter.text.ungrouped'),
				this.messageService.getMessage('boolean_measure_value_true'),
				this.messageService.getMessage('boolean_measure_value_false')
			)
			.subscribe(([language, message, booleanMeasureMessageTrue, booleanMeasureMessageFalse]: [Language, string, string, string]): void => {
				this.itemsComboboxOptions = this.initItemsComboboxOptions(items, groups, language, message);
				this.booleanMeasuresComboboxOptions = this.initBooleanMeasuresComboboxOptions(booleanMeasureMessageTrue, booleanMeasureMessageFalse);

				let hasMoneyMeasure = false;
				const categoryItems: Array<ItemDto> = [];
				const measureItems: Array<ItemDto> = [];
				items.forEach((item: ItemDto): void => {
					if (item instanceof CategoryItemDto) {
						categoryItems.push(item);
					} else if (item instanceof MeasureItemDto) {
						hasMoneyMeasure = (item.measureType === MeasureType.MONEY) || hasMoneyMeasure;
						measureItems.push(item);
					}
				});

				this.categoryComboboxOptions = CommonFilterComponent.initSimpleComboboxOptions(categoryItems);
				this.measureComboboxOptions = CommonFilterComponent.initSimpleComboboxOptions(measureItems);

				if (hasMoneyMeasure) {
					this.currencyComboboxSubscription = this.valueLoader.getCurrencies()
						.subscribe((currencies: Array<CurrencyValue>): void => {
							this.currencyComboboxOptions = CommonFilterComponent.initSimpleComboboxOptions(currencies);
						});
				}
			});
	}

	private initItemsComboboxOptions(items: ItemsMap, groups: Array<ItemGroupDto>, currentLanguage: Language, defaultGroupMessage: string): ComboboxOptions<ComboboxOption<ItemDto, string>> {
		PreconditionCheck.notNullOrUndefined(items);
		PreconditionCheck.notNullOrUndefined(groups);
		PreconditionCheck.notNullOrUndefined(currentLanguage);
		PreconditionCheck.notNullOrUndefined(defaultGroupMessage);

		const options: ComboboxOptions<ComboboxOption<ItemDto, string>> = [];
		const groupedItems: Map<number | string, number> = new Map();

		groups.forEach((group: ItemGroupDto) => {
			group.items.forEach((orderedItem: OrderedItemDto) => {
				const itemId: number | string = orderedItem.itemId;

				if (!items.has(itemId)) {
					return;
				}

				const item: ItemDto = items.get(itemId);
				const itemName: string = item.name.get(currentLanguage.getId());
				const itemGroup: string = group.name.get(currentLanguage.getId());

				options.push(new ComboboxOption(item, itemName || '', itemGroup || ''));

				groupedItems.set(itemId, group.id);
			});
		});

		items.forEach((item: ItemDto): void => {
			if (groupedItems.has(item.id)) {
				return;
			}

			options.push(new ComboboxOption(item, item.name.get(currentLanguage.getId()) || '', defaultGroupMessage));
		});

		return options;
	}

	private initBooleanMeasuresComboboxOptions(booleanMeasureMessageTrue: string, booleanMeasureMessageFalse: string): ComboboxOptions<ComboboxOption<number, string>> {
		const trueBooleanMeasureComboboxOption: ComboboxOption<number, string> = new ComboboxOption<number, string>(1, booleanMeasureMessageTrue);
		const falseBooleanMeasureComboboxOption: ComboboxOption<number, string> = new ComboboxOption<number, string>(0, booleanMeasureMessageFalse);

		return [trueBooleanMeasureComboboxOption, falseBooleanMeasureComboboxOption];
	}

	private clearCurrencyComboboxSubscription(): void {
		if (this.currencyComboboxSubscription) {
			this.currencyComboboxSubscription.unsubscribe();
		}
		this.currencyComboboxSubscription = null;
	}

	private emitFilterTreeDtoChange(): void {
		this.filterTreeDtoEmitted = this.toFilterTreeDto(this.filterGroups);
		this.filterTreeDtoChange.emit(this.filterTreeDtoEmitted);
	}

	private validateItems(onlyThoseRequiringValidation: boolean = false) {
		this.filterGroups.forEach((filterGroup: FilterGroup): void => {
			filterGroup.validate(onlyThoseRequiringValidation);
		});
	}

	private clearValiditySubscription(): void {
		if (this.validitySubscription) {
			this.validitySubscription.unsubscribe();
		}
		this.validitySubscription = null;
	}

	private resetValiditySubscription(init: boolean = false): void {
		PreconditionCheck.notUndefined(init);

		this.clearValiditySubscription();

		let validityObservable: Observable<boolean>;
		let validationObservables: Array<Observable<Array<boolean>>>;

		validationObservables = this.filterGroups
			.map((filterGroup: FilterGroup): Observable<Array<boolean>> => {
				return filterGroup.isValid();
			});

		validationObservables.push(Observable.of([true])); // In case of empty filter ensure its validness!

		validityObservable = Observable.combineLatest(validationObservables, (...groupValidations: Array<Array<boolean>>): boolean => {
			const allValidations: Array<boolean> = groupValidations
				.reduce((accumulator: Array<boolean>, itemValidations: Array<boolean>): Array<boolean> => {
					return accumulator.concat(itemValidations);
				}, []);

			const anyInvalid = allValidations
				.some((isValid: boolean): boolean => {
					return !isValid;
				});

			return !anyInvalid;
		});

		this.validitySubscription = validityObservable
			.debounceTime(500)
			.skip((init ? 1 : 0)) // Skip emission of the result of initial mass validation!
			.subscribe((isValid: boolean): void => {
				this.isError = false;

				if (isValid) {
					if (this.requiresValidation()) {
						this.validateItems(true);
					} else {
						this.emitFilterTreeDtoChange();
					}
				}

				this.isValid.emit(isValid);
			});
	}

	private requiresValidation(): boolean {
		return this.filterGroups.some((filterGroup: FilterGroup): boolean => {
			return filterGroup.filterItems.some((filterItem: FilterItem): boolean => {
				return filterItem.requiresCriterionValidation();
			});
		});
	}

	private fromFilterTreeDto(): Array<FilterGroup> {
		let filterGroups: Array<FilterGroup> = [];

		if (this.filterTreeDto && this._items) {
			const filterRoot: FilterTreeOperatorDto = this.filterTreeDto.rootOperator;
			const children: Array<AbstractFilterElementDto> = filterRoot ? filterRoot.children : [];

			try {
				let defaultFilterGroup: FilterGroup = new FilterGroup();

				children.forEach((child: AbstractFilterElementDto): void => {
					let lastFilterGroup: FilterGroup;

					if (child instanceof FilterTreeOperatorDto) {
						// Common case when there are many "or" with "and" operators - the tree is complete.
						lastFilterGroup = this.fromOperator(child);
					} else if (child instanceof FilterDto) {
						// When there is only one "or" with "and" operators - the root "or" is missing from the tree.
						defaultFilterGroup = this.fromFilter(child, defaultFilterGroup);
					}

					if (lastFilterGroup && lastFilterGroup.filterItems.length > 0) {
						filterGroups.push(lastFilterGroup);
					}
				});

				if (defaultFilterGroup.filterItems.length > 0) {
					filterGroups.push(defaultFilterGroup);
				}
			} catch (err) {
				filterGroups = [];

				console.error('Failed with the following error: %o', err);
				this.isError = true;
			}
		}

		// If no groups present then add one group for user's convenience.
		if (filterGroups.length === 0) {
			filterGroups.push(this.createEmptyFilterGroup());
		}

		return filterGroups;
	}

	/**
	 * Creates new filter group with an initial empty item.
	 * @returns {FilterGroup} The created group
	 */
	private createEmptyFilterGroup(): FilterGroup {
		// Create one group with an empty item.
		const filterGroup: FilterGroup = new FilterGroup();
		const filterItem: FilterItem = new FilterItem(this, new CriterionValidator());
		filterGroup.filterItems.push(filterItem);

		return filterGroup;
	}

	private fromOperator(filterTreeOperatorDto: FilterTreeOperatorDto): FilterGroup {
		PreconditionCheck.notNullOrUndefined(filterTreeOperatorDto);

		let filterGroup: FilterGroup = new FilterGroup();
		const children: Array<AbstractFilterElementDto> = filterTreeOperatorDto.children;

		children.forEach((child: FilterDto): void => {
			filterGroup = this.fromFilter(child, filterGroup);
		});

		return filterGroup;
	}

	private fromFilter(filterDto: FilterDto, filterGroup: FilterGroup): FilterGroup {
		PreconditionCheck.notNullOrUndefined(filterDto);
		PreconditionCheck.notNullOrUndefined(filterGroup);

		const filterItem: FilterItem = FilterHelper.filterItemFromDTO(this, filterDto);

		if (filterItem) {
			filterGroup.filterItems.push(filterItem);
		} else {
			throw new Error(`Unhandled filter DTO type (was '${filterDto.constructor.name}').`);
		}

		return filterGroup;
	}

	private toFilterTreeDto(filterGroups: Array<FilterGroup>): FilterTreeDto {
		PreconditionCheck.notNullOrUndefined(filterGroups);

		let overallResult: FilterTreeDto = FilterDtoBuilder.createOrFilterBuilder().build();

		try {
			overallResult = FilterHelper.filterGroupsToDTO(filterGroups);
		} catch (err) {
			console.error('Failed with the following error: %o', err);
			this.isError = true;
		}

		return overallResult;
	}

	public showHideHeader(event: any) {
		this.isHeaderHidden = ((event.target.scrollTop > 68) && (event.target.scrollTop > this.lastScroll));
		this.lastScroll = event.target.scrollTop;
	}
}

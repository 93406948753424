import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {ControlsModule} from '@synisys/idm-ng-controls';
import {CommonFilterComponent} from './common-filter/common-filter.component';
import {MessagePipe} from '../shared/message.pipe';
import {MultilingualPipe} from '../shared/multilingual.pipe';
import {MaterialModule} from './material.module';
import {EntitySearchModule} from '@synisys/idm-entity-search-client-js';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ControlsModule,
		MaterialModule,
		ReactiveFormsModule,
		EntitySearchModule
	],
	declarations: [
		MessagePipe,
		MultilingualPipe,
		CommonFilterComponent
	],
	exports: [
		MessagePipe,
		MultilingualPipe,
		CommonFilterComponent
	],
	providers: [
	]
})
export class FilteringControlsModule {
}
